<template>
  <div>
    <v-container class="pa-0">
      <div>
        <v-card
          v-if="$vuetify.breakpoint.mdAndUp"
          width="100%"
          flat
          class="d-flex"
          style="background: rgba(127, 145, 155, 0.052607)"
        >
          <v-switch
            flat
            disabled
            class="pl-4 mb-2"
            color="#16be98"
            v-model="autoProcess"
            label="Auto process"
          >
          </v-switch>

          <v-switch
            flat
            @change="triggerDialog"
            hide-details="true"
            v-model="sendToWorkflowState"
            class="px-4 mb-md-1"
            :color="`${(hasColor = true ? '#16BE98' : '')}`"
            label="Send to workflow"
          >
          </v-switch>

          <v-chip class="mt-5" small
            ><span class="workflow__text">
              {{ workflowName || "workflow name…" }}
            </span>
          </v-chip>
          <v-spacer></v-spacer>
          <download-csv
            class="btn btn-default"
            :data="filteredInvoices"
            v-if="filteredInvoices && filteredInvoices.length > 0"
          >
            <v-hover v-slot="{ hover }">
              <v-btn
                outlined
                @click="alertCSVDownload"
                color="primary"
                class="my-4 export-btn mr-9 hover-btn"
                :style="{
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }"
                elevation="2"
                ><span class="material-icons pr-1"> import_export </span
                ><span class="primary--text export-btn">Export</span>
              </v-btn>
            </v-hover>
          </download-csv>
        </v-card>
        <div
          v-if="$vuetify.breakpoint.mdAndDown"
          class="d-flex flex-column justify-center align-center"
        >
          <div class="py-3 ma-0 text-subtitle-1 text-center primary--text">
            {{ workflowName || "workflow name…" }}
          </div>
          <!-- checkboxes for mobile devices -->
          <div class="pa-0 ma-0 d-flex justify-center align-center">
            <v-checkbox
              class="pl-2 mb-0 py-0"
              color="#16be98"
              v-model="autoProcess"
            >
              <template v-slot:label>
                <span class="mb-0 py-0" style="font-size: 12px"
                  >Auto process</span
                >
              </template>
            </v-checkbox>

            <v-checkbox
              flat
              dense
              @change="triggerDialog"
              hide-details="true"
              v-model="sendToWorkflowState"
              class="px-4 py-0"
              :color="`${(hasColor = true ? '#16BE98' : '')}`"
            >
              <template v-slot:label>
                <span class="py-0" style="font-size: 12px"
                  >Send to workflow</span
                >
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>

      <!-- inbox tabs for desktop screens -->
      <v-skeleton-loader type="table" :loading="isLoading">
        <v-layout
          row
          wrap
          class="align-center pt-md-6 pb-md-4 px-8 text-body-2"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-flex md1>
            <div>
              <p class="mb-0 primary--text font-weight-bold">#</p>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold text-center">
                Type
              </p>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center pl-4">
              <p class="mb-0 primary--text font-weight-bold text-center">
                Currency
              </p>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 pl-4 primary--text font-weight-bold">Amount</p>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Requester</p>
            </div>
          </v-flex>
          <v-flex md1>
            <div>
              <p class="mb-0 pl-md-1 primary--text font-weight-bold">Date</p>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 pl-md-8 primary--text font-weight-bold">Actions</p>
            </div>
          </v-flex>
        </v-layout>
        <v-row v-if="allInvoices && allInvoices.length > 0" class="my-0">
          <v-col
            cols="12"
            v-for="invoice in displayedItems"
            :key="invoice.index"
            class="py-0 ma-md-0"
          >
            <DataTable
              v-if="$vuetify.breakpoint.mdAndUp"
              :index="invoice.index"
              :id="invoice.id"
              :invoiceRef="invoice.invoicenumber || invoice.ref"
              :type="invoice.invoicetype"
              :requester="
                (invoice.vendor && invoice.vendor.vendorname) ||
                (invoice.customer &&
                  invoice.customer.firstname +
                    ' ' +
                    invoice.customer.lastname) ||
                'N/A'
              "
              :date="invoice.created_at | date"
              :amount="invoice.total"
              :currency="invoice.currency"
              :status="invoice.status"
              :category="invoice.category"
              :textColor="
                invoice.invoicetype === 'customer' ? '#E3AA1C' : '#2BD5AE'
              "
            />

            <!-- Data table for mobile -->
            <DataTableCard
              v-if="$vuetify.breakpoint.smAndDown"
              :index="invoice.id"
              :invoiceRef="invoice.invoicenumber || 'N/A'"
              :type="invoice.invoicetype"
              :requester="
                (invoice.vendor && invoice.vendor.vendorname) ||
                (invoice.customer &&
                  invoice.customer.firstname +
                    ' ' +
                    invoice.customer.lastname) ||
                'N/A'
              "
              :date="invoice.created_at | date"
              :amount="invoice.total || 'N/A'"
              :status="invoice.status || 'N/A'"
              :category="invoice.category || 'N/A'"
              :chipColor="
                invoice.invoicetype === 'customer' ? '#2BD5AE' : '#E3AA1C'
              "
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-center align-center pt-6" v-else>
          <span class="text--primary text-h5 font-monospace"
            >No Data available</span
          >
        </div>
        <div
          class="pagination__container px-8"
          v-if="allInvoices && allInvoices.length > 0"
        >
          <BasePagination
            :current-page="currentPage"
            :visible-pages="visiblePageValue"
            :total-pages="totalPages"
            @page-changed="handlePageChange"
          />
        </div>
      </v-skeleton-loader>
    </v-container>
    <SendToWorkflowDialog
      ref="sendToWorkflowDialog"
      @getWorkflowTitle="setWorkflowTitle"
      @closeDialog="updateSendToWorkflowState"
    />
  </div>
</template>

<script>
import SendToWorkflowDialog from "../includes/overlays/SendToWorkflowDialog.vue";
import DataTable from "./DataTable.vue";
import DataTableCard from "./DataTableCard.vue";
import { mapActions, mapGetters } from "vuex";
import pagination from "@/mixins/pagination";

import BasePagination from "@/components/BasePagination.vue";

export default {
  components: {
    SendToWorkflowDialog,
    DataTable,
    DataTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      autoProcess: true,
      searchQuery: "",
      selectedRows: [],
      sendToWorkflowState: null,
      workflowName: "workflow name…",
      switchState: false,
      isLoading: false,
      itemsPerPage: 10,
      workflow: null,
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    setWorkflowTitle(value) {
      if (!value) {
        //this.dialog = true;
        this.switchState = false;
      } else if (value) {
        this.switchState = true;
      }

      this.workflowName = value;
      if (process.env.NODE_ENV === "development") {
        console.log(value);
      }
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
    //trigger the sendToWorkflowDialog
    triggerDialog() {
      if (this.sendToWorkflowState === true) {
        this.$refs.sendToWorkflowDialog.openDialog(this.sendToWorkflowState);
      }
    },

    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },

    updateSendToWorkflowState(val) {
      this.sendToWorkflowState = val;
    },
  },
  computed: {
    //get full name of customer
    ...mapGetters({
      allInvoices: "invoices/getInvoices",
      organisationId: "auth/organizationId",
      pagination: "invoices/getPagination",
    }),

    filteredInvoices() {
      if (this.searchQuery) {
        if (process.env.NODE_ENV === "development") {
          console.log(this.searchQuery);
        }

        return this.allInvoices?.filter((invoice) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => {
              if (invoice.invoicetype == "vendor") {
                return (
                  invoice.invoicenumber.toLowerCase().includes(v) ||
                  invoice.invoicetype.toLowerCase().includes(v) ||
                  invoice.total.toLowerCase().includes(v) ||
                  // invoice.customer?.firstname.toLowerCase().includes(v) ||
                  // invoice.customer?.lastname.toLowerCase().includes(v) ||
                  invoice.vendor?.vendorname.toLowerCase().includes(v)
                );
              } else if (
                invoice.invoicetype == "customer" &&
                invoice.customer &&
                invoice.customer.firstname !== null
              ) {
                return (
                  invoice.invoicenumber.toLowerCase().includes(v) ||
                  invoice.invoicetype.toLowerCase().includes(v) ||
                  invoice.total.toLowerCase().includes(v) ||
                  invoice.customer?.firstname.toLowerCase().includes(v) ||
                  invoice.customer?.lastname.toLowerCase().includes(v)
                  // invoice.vendor?.vendorname.toLowerCase().includes(v)
                );
              }
            });
        });
      } else return this.allInvoices;
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredInvoices?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (
        this.filteredInvoices &&
        this.filteredInvoices.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredInvoices?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredInvoices?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    },
  },
  async mounted() {
    //make skeleton loader stop
    this.isLoading = true;
    setTimeout(
      () => {
        this.isLoading = false;
      },
      3000,
      this.allInvoices
    );

    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(this.allInvoices, null, 2));
    }

    this.workflow = await this.$store.dispatch("workflow/getAllWorkflows");
  },
};
</script>

<style>
.v-input__slot {
  margin-bottom: 0px;
}

.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

hover-btn:hover {
  background-color: lightseagreen;
  color: midnightblue;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  /* margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 30px;
  padding-right: -20px; */
}

tbody tr:nth-of-type(odd) {
  background-color: #f8f9fc;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

table td,
table th {
  height: 40px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 36px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.workflow__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  color: #7f919b;
}

.export-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;
}
</style>
