var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-6 py-4 my-1",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''},on:{"click":function($event){return _vm.$router.push({
          name: 'inbox.invoice',
          params: { id: _vm.index },
          query: { exception: true },
        })}}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"mb-0 mx-4",staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.index))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-chip',{staticClass:"mx-4",attrs:{"color":_vm.chipcolor,"text-color":_vm.textColor,"x-small":""}},[_vm._v(_vm._s(_vm.type))])],1)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"mb-0",staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.amount))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('span',{staticClass:"mb-0 text-center",staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.exception))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 text-truncate",staticStyle:{"max-width":"139px","font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.invoiceRef))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.invoiceRef))])])],1)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"small":"","color":_vm.statusIcon}},[_vm._v(" mdi-circle-medium ")]),_c('span',{staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.status))])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0",staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.requester))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',[_c('span',{staticClass:"mb-0",staticStyle:{"font-family":"Lato","font-style":"normal","font-weight":"normal","font-size":"12px","line-height":"14px","color":"#596a73"}},[_vm._v(_vm._s(_vm.date))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
                    name: 'inbox.invoice',
                    params: { id: _vm.id },
                    query: { exception: true },
                  })}}},[_vm._v(" view ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                  'background-color': hover ? '#c4c9cf' : '',
                  border: hover ? 'none' : '',
                  color: hover ? 'white' : '#ffffff',
                }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                    name: 'inbox.invoice',
                    params: { id: _vm.id },
                    query: { review: false },
                  })}}},[_vm._v("review")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }