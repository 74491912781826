<template>
  <v-container>
    <!-- <v-skeleton-loader width="90%" type="table" :loading="loading"> -->
    <v-row>
      <v-col cols="12">
        <BaseTitleBar
          pageTitle="Invoice"
          itemsText="Transaction(s)"
          :itemArray="getInvoices"
          :total-items="totalInvoice"
        >
          <DropDownMenu btnText="New" icon="file" height="54px" justify="right">
            <template #titleButton>
              <v-list-item-group>
                <v-list>
                  <v-list-item
                    class="d-flex align-start"
                    v-for="(item, index) in inboxMenus"
                    :key="index"
                    @click="handleMenuAction(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title tag="button" slot="activator">
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <template> {{ item.subtitle }}</template>
                        <template v-if="item.title == 'Receive Vendor Invoice'">
                          {{
                            organizationToken && organizationToken.data
                              ? organizationToken.data.hypn_email
                              : ""
                          }}
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-icon small class="pt-4"> {{ item.icon }}</v-icon>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
              <UploadDialog ref="uploadDialog" />
            </template>
          </DropDownMenu>
        </BaseTitleBar>
        <!-- an animated loader component -->
        <div class="mx-auto mt-8" style="max-width: 250px" v-if="refreshing">
          <v-progress-linear
            width="100%"
            color="primary"
            indeterminate
            rounded
            height="8"
          ></v-progress-linear>
        </div>
      </v-col>
    </v-row>
    <div id="noInvoice" v-if="noData && !refreshing">
      <!-- instructions when there is no invoice -->

      <no-invoice />
    </div>
    <div id="invoiceIsAvaliable" v-if="!refreshing && !noData">
      <!-- inbox summary Info cards -->
      <InfoSummaryContainer class="mx-md-6" v-if="organizationToken">
        <template #cardOne>
          <single-summary-card :loading="loadingInvoices">
            <template #avatar>
              <card-avatar
                :isText="false"
                :style="{ background: avatarBC.one }"
                icon="mdi-email-outline"
              />
            </template>
            <template #cardBody>
              <div class="pr-2 pl-4">
                <p class="pt-1 my-0 h-card-title">Incoming invoice/bill</p>

                <p
                  v-if="organizationToken.data"
                  class="pt-1 pr-1 my-md-1 text-wrap h-card-body-bold"
                >
                  {{
                    organizationToken ? organizationToken.data.hypn_email : ""
                  }}
                </p>
                <p class="pt-1 text-wrap h-card-body">
                  Forward your incoming invoices/bills to this address
                </p>
              </div>
            </template>
          </single-summary-card>
        </template>
        <template #cardTwo>
          <single-summary-card :loading="loadingInvoices">
            <template #avatar>
              <card-avatar
                :aContent="
                  organizationToken ? organizationToken.data.invoices_mth : ''
                "
                :style="{ background: avatarBC.two }"
              />
            </template>
            <template #cardBody>
              <div class="pr-8 pl-4">
                <p class="pt-6 my-0 h-card-title">Volume this month</p>
                <p class="pt-2 h-card-body">
                  Total invoices/bills received in the last 30days
                </p>
              </div>
            </template>
          </single-summary-card>
        </template>
        <template #cardThree>
          <single-summary-card :loading="loadingInvoices">
            <template #avatar>
              <card-avatar
                :aContent="NumOfExceptions"
                :style="{ background: avatarBC.three }"
              />
            </template>
            <template #cardBody>
              <div class="pr-8 pl-4">
                <p class="pt-5 my-0 h-card-title">Exceptions</p>
                <p class="pt-2 text-wrap h-card-body">
                  Total number of invoices/bills that require manual oversight
                </p>
              </div>
            </template>
          </single-summary-card>
        </template>
      </InfoSummaryContainer>
      <!-- inbox table for desktop screen -->
      <v-row class="px-2 px-md-0">
        <v-col cols="12">
          <v-card
            flat
            elevation="6"
            max-width="100%"
            min-width="320px"
            min-height=""
            class="mx-auto mx-md-14 mb-10 pb-10"
          >
            <v-skeleton-loader
              width="100%"
              type="table"
              :loading="loadingInvoices"
            >
              <div class="mt-md-12 mx-0">
                <v-card
                  flat
                  max-width="100%"
                  style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
                >
                  <v-tabs
                    align-with-title
                    slider-size="4"
                    v-model="tab"
                    mobile-breakpoint="5"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-tab
                      class="mt-2"
                      v-for="item in items"
                      :key="item.tab"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;
                        text-transform: uppercase;
                      "
                    >
                      <v-icon
                        v-if="item.tab == 'Exception'"
                        left
                        color="#ff6a6a"
                        small
                        class="pr-1 mr-0"
                      >
                        mdi-stop-circle-outline
                      </v-icon>
                      <span
                        class="font-weight-bold"
                        :style="{
                          color: `${item.tab == 'Exception' ? '#ff6a6a' : ''}`,
                        }"
                        >{{ item.tab }}</span
                      >
                    </v-tab>

                    <v-spacer></v-spacer>
                    <v-btn
                      class="pt-4 mt-1"
                      plain
                      @click.prevent="HandleInviteDialog"
                    >
                      <simple-line-icons
                        left
                        class="pr-1"
                        icon="people"
                        no-svg
                      />

                      <b class="invitea__btn">invite</b>
                    </v-btn>
                    <v-btn
                      v-if="isClicked"
                      @click="toggleSearch"
                      plain
                      class="text-black mt-1 pt-4 search__btn"
                    >
                      search
                      <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                    </v-btn>
                    <v-expand-x-transition v-else>
                      <v-text-field
                        @input="searchDataTable"
                        v-model="search"
                        @blur="isClicked = true && !search"
                        class="seacrh-field mt-2 mr-2"
                        dense
                        clearable
                        autofocus
                        hide-details="true"
                        persistent-placeholder
                        placeholder="Search"
                        append-icon="mdi-magnify"
                        filled
                      >
                      </v-text-field>
                    </v-expand-x-transition>
                  </v-tabs>
                </v-card>
              </div>
              <component
                v-bind:is="items[tab].content"
                class="ml-0"
                ref="dataTable"
              ></component>
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- inbox dialog starts here -->
    <InviteStakeholder ref="inviteStakeholder" />
    <!-- inbox dialog ends here -->

    <!-- tabs for mobile devices -->
    <v-container
      class="pa-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-row>
        <v-col cols="12">
          <v-bottom-navigation fixed class="pa-0" dark>
            <v-tabs
              centered
              class="ma-0"
              background-color="primary"
              v-model="tab"
            >
              <v-tab
                class="mt-2"
                v-for="item in items"
                :key="item.tab"
                style="
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 15px;
                  text-transform: uppercase;
                "
                >{{ item.tab }}</v-tab
              >
            </v-tabs>
          </v-bottom-navigation>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TabDataTableAll from "../../components/TabDataTableAll.vue";
import TabDataTableException from "../../components/TabDataTableException.vue";
import TabDataTableVendors from "../../components/TabDataTableVendors.vue";
import TabDataTableCustomers from "../../components/TabDataTableCustomers.vue";
import DropDownMenu from "../../includes/DropDownMenu.vue";
import SimpleLineIcons from "vue-simple-line";
import NoInvoice from "../../components/noInvoice.vue";
import InfoSummaryContainer from "../../components/InfoSummaryContainer.vue";
import SingleSummaryCard from "../../components/SingleSummaryCard.vue";
import CardAvatar from "../../components/CardAvatar.vue";
import BaseTitleBar from "@/components/BaseTitleBar.vue";
import UploadDialog from "../../includes/overlays/uploadDialog.vue";
import InviteStakeholder from "./components/inviteStakeholder.vue";

export default {
  name: "Inbox",
  data() {
    return {
      isInviting: false,
      noData: false,
      loadingInvoices: false,
      refreshing: false,
      dialog: false,
      isClicked: true,
      stakeholderTxt: ` Stakeholder`,
      tab: 0,
      tab1: 0,
      search: "",
      page: 1,
      pagination: {
        start: 0,
        limit: 10,
        total: 0,
      },

      items: [
        { tab: "All", content: "TabDataTableAll" },
        // { tab: "Reviews", content: "TabDataTableReviews" },
        { tab: "Customers", content: "TabDataTableCustomers" },
        { tab: "Vendors", content: "TabDataTableVendors" },
        { tab: "Exception", content: "TabDataTableException" },
      ],
      inboxMenus: [
        {
          title: "Send Customer Invoice",
          subtitle: "",
          icon: "mdi-text-box-outline ",
          path: "#",
        },
        {
          title: "Process Vendor Invoice",
          subtitle: "",
          icon: "mdi-inbox-arrow-down-outline ",
          path: "",
        },

        {
          title: "Request Payment",
          subtitle: "",
          icon: "",
          path: "#",
        },
      ],
      avatarSizes: {
        xl: 88,
        xs: 40,
        lg: 72,
        md: 64,
        sm: 48,
      },

      cardWidth: {
        xs: "100%",

        md: "630px",
      },
      avatarBC: {
        one: "#d5f7ef",
        two: "#eef3fb",
        three: "#f8f7f4",
      },
    };
  },
  components: {
    TabDataTableAll,
    TabDataTableException,
    TabDataTableVendors,
    TabDataTableCustomers,
    DropDownMenu,
    SimpleLineIcons,
    NoInvoice,
    InfoSummaryContainer,
    SingleSummaryCard,
    CardAvatar,
    BaseTitleBar,
    UploadDialog,
    InviteStakeholder,
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    handleMenuAction(index) {
      this.getAllCustomers();
      this.fetchAllBankAccounts();
      if (index === 0) {
        // open Make customer Invoice page

        this.$router.push({ name: "NewInvoice" });
      } else if (index === 1) {
        //this.$refs.uploadDialog.OpenDialog();
        // this.$refs.createDialog.OpenDialog();
      } else if (index === 2) {
        this.$router.push({ name: "RequestPayment" });
      }
    },

    // fetch banks
    async fetchAllBankAccounts() {
      let organizationId = await this.$orgId;

      console.log(organizationId);

      await this.$store.dispatch(
        "organizations/getOrgBankAccounts",
        organizationId
      );
    },
    //fetch all customers
    async getAllCustomers() {
      try {
        await this.$store.dispatch("contacts/fetchAllCustomers", this.$orgId);
      } catch (error) {
        if (process.env.NODE_ENV === "developement") {
          console.log(error);
        }
      }
    },

    //show the invite stakeholder dialog
    HandleInviteDialog() {
      this.$refs.inviteStakeholder.OpenDialog();
    },
    toggleSearch() {
      this.isClicked = false;
    },

    //method to handle the search
    searchDataTable(e) {
      this.$refs.dataTable.setSearchText(e);
    },
    async sendInvite() {
      this.isInviting = true;

      // const orgId = this.organizationToken.data.organization;
      const payload = {
        username: this.getUsername,
        first_name: this.stakeholder.firstName,
        last_name: this.stakeholder.lastName,
        // role: this.stakeholder.selectedType,
        email: this.stakeholder.email,
        organization: this.organizationToken?.data?.organization,
      };
      // console.log(JSON.stringify(payload, null, 2));
      // console.log(orgId);

      try {
        await this.$store.dispatch(
          "organizations/inviteCoWorker",

          payload
        );

        //show success toast
        this.showToast({
          sclass: "success",
          show: true,
          message: "co-worker successfully created",
          timeout: 3000,
        });
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(error, null, 2));
        }
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      }
    },

    async getOrganizationToken() {
      try {
        const { data } = await this.$store.dispatch(
          "organizations/getOrganizationToken"
        );
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(error, null, 2));
        }
      }
    },

    async getAllInvocies() {
      try {
        this.loadingInvoices = true;
        const { data } = await this.$store.dispatch(
          "invoices/FetchAllInvoices"
        );
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(err, null, 2));
        }
      } finally {
        this.loadingInvoices = false;
      }
    },
  },
  mounted() {
    // this.refreshing = false;
    // setInterval(() => {
    //   this.refreshing = false;
    // }, 4000);

    this.getAllInvocies();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      invoiceArrayStatus: "invoices/checkInvoiceArray",
      totalInvoice: "invoices/numOfInvoices",
      getInvoices: "invoices/getInvoices",
      NumOfExceptions: "invoices/checkNumberOfExceptions",
      organizationToken: "organizations/OrganToken",
      //organizationEmail: "organizations/getOrganizationEmail",
    }),
    // ...mapState({
    //   organization: "organization",
    //   allInvoices: "invoices",
    //   formCards: "formBuilder",
    // }),

    getOrgId() {
      //console.log(this.$store.state.auth.user);
      return this.$store.state.auth.user;
    },
  },

  watch: {
    getOrgId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getOrganizationToken();
          this.getAllInvocies();

          //console.log(JSON.stringify(val, null, 2));
          // this.$store.dispatch("invoices/FetchAllInvoices");
        }
      },
    },

    getInvoices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (typeof val !== "undefined" && val.length === 0) {
          //console.log(JSON.stringify(val, null, 2));
          this.noData = true;
        } else {
          this.noData = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.h-card-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 35, 56, 0.5);
}

.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
}

.h-card-body-bold {
  max-width: 16rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* letter-spacing: 0.545455px; */
  color: #19283d;
}

.h-card-body {
  max-width: 274px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 35, 56, 0.5);
}

.v-input .search-field .v-input__slot:before,
.v-input .search-field .v-input__slot:after {
  border: none !important;
  border-color: transparent !important;
}

.transTotal {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.invite__btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
}

.search__btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  color: #7f919b;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

th {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--v-primary-base);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}
</style>
