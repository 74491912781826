<template>
  <div>
    <div>
      <v-card
        width="100%"
        min-height="70px"
        flat
        class="d-flex"
        style="background: rgba(127, 145, 155, 0.052607)"
      >
      </v-card>
    </div>
    <v-layout
      row
      wrap
      class="align-center my-2 px-6"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex md1>
        <div class="d-flex align-center justify-center">
          <p class="mb-0 primary--text font-weight-bold text-center">#</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center justify-center">
          <p class="mb-0 primary--text font-weight-bold text-center">Type</p>
        </div>
      </v-flex>

      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 pl-4 primary--text font-weight-bold">Amount</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center justify-center">
          <p class="mb-0 primary--text font-weight-bold">Exception</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Status</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Requester</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div>
          <p class="mb-0 pl-md-4 primary--text font-weight-bold">Date</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 pl-md-8 primary--text font-weight-bold">Actions</p>
        </div>
      </v-flex>
    </v-layout>
    <v-row v-if="allInvoices && allInvoices.length > 0">
      <v-col
        cols="12"
        v-for="invoice in displayedItems"
        :key="invoice.index"
        class="py-0 ma-0"
      >
        <ExceptionTable
          v-if="$vuetify.breakpoint.mdAndUp"
          :index="invoice.index"
          :id="invoice.id"
          :invoiceRef="invoice.invoicenumber"
          :type="invoice.invoicetype"
          :requester="invoice.vendor && invoice.vendor.vendorname"
          :exception="invoice.exception"
          :date="invoice.created_at | date"
          :amount="invoice.total"
          :status="invoice.status"
          :textColor="invoice.invoicetype === 'expense' ? '#E3AA1C' : '#2BD5AE'"
        />

        <!-- Data table for mobile -->

        <ExceptionTableCard
          v-else
          :index="invoice.index"
          :invoiceRef="invoice.invoicenumber"
          :type="invoice.invoicetype"
          :requester="invoice.vendor && invoice.vendor.vendorname"
          :date="invoice.created_at | date"
          :amount="invoice.total"
          :status="invoice.status"
          :exception="invoice.exception"
          :chipColor="invoice.invoicetype === 'expense' ? '#2BD5AE' : '#E3AA1C'"
          :statusColor="invoice.status === 'processed' ? '#2BD5AE' : '#E3AA1C'"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center pt-6" v-else>
      <span class="text--primary text-h5 font-monospace"
        >No Data available</span
      >
    </div>
    <div
      class="pagination__container px-8"
      v-if="allInvoices && allInvoices.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
//import DataTable from "./DataTable.vue";
import ExceptionTable from "./ExceptionTable.vue";
import ExceptionTableCard from "./ExceptionTableCard.vue";

import pagination from "@/mixins/pagination";

import BasePagination from "@/components/BasePagination.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    //DataTable,
    ExceptionTable,
    ExceptionTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      autoProcess: true,
      selectedRows: [],
      sendToWorkflow: true,
      workflowName: "workflow name…",
      searchQuery: "",
      itemsPerPage: 10,
    };
  },
  methods: {
    closeModal(e) {
      this.dialog = false;
      this.workflowName = e;
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
  },
  computed: {
    ...mapGetters({
      allInvoices: "invoices/getInvoices",
    }),
    filteredInvoices() {
      if (this.searchQuery) {
        return this.allInvoices?.filter((invoice) => {
          invoice.requester
            .toLowerCase()
            .match(this.searchQuery.toLowerCase()) ||
            invoice.status.toLowerCase().match(this.searchQuery.toLowerCase) ||
            invoice.amount.toString().match(this.searchQuery) ||
            invoice.exception.toString().match(this.searchQuery);
        });
      } else
        return this.allInvoices?.filter((invoice) => {
          if (invoice.exception == 1) {
            return invoice;
          }
        });
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredInvoices?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (
        this.filteredInvoices &&
        this.filteredInvoices.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredInvoices?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredInvoices?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
