<template>
  <div class="d-flex align-center justify-center mt-6 px-2">
    <v-card
      max-height="40vh"
      min-width="100%"
      outlined
      class="my-4 mx-8 rounded-lg"
      @click="
        $router.push({
          name: 'inbox.invoice',
          params: { id: id },
          query: { exception: true },
        })
      "
    >
      <h5 class="px-auto mt-8 text-bold text-h5 text-center primary--text">
        {{ exception }} Exceptions
      </h5>
      <template>
        <v-simple-table dense>
          <template v-slot:default>
            <thead class="px-0">
              <tr class="px-0">
                <th class="px-0 text-center primary--text text-subtitle-2">
                  Amount
                </th>
                <th class="px-0 text-center primary--text text-subtitle-2">
                  Ref. No
                </th>
                <th class="px-0 text-center primary--text text-subtitle-2">
                  Requester
                </th>
              </tr>
            </thead>
            <tbody class="px-0">
              <tr class="px-0">
                <td class="px-0 text-center">
                  {{ amount }}
                </td>
                <td class="px-0 text-center">{{ invoiceRef }}</td>
                <td class="px-0 text-center">{{ requester }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <v-row class="pa-0 ma-0">
        <v-col class="d-flex justify-space-around align-center px-2">
          <v-chip
            class="mx-2 pa-1 text-center"
            outlined
            x-small
            :color="chipColor"
            ><span class="chiptext">{{ type }}</span></v-chip
          >
          <v-chip
            class="mx-2 pa-1 text-center"
            outlined
            x-small
            :color="statusColor"
            ><span class="chiptext px-2 ma-0">{{ status }}</span></v-chip
          >
          <v-chip class="mx-2" x-small
            ><span class="chiptext">{{ date }}</span></v-chip
          >
          <template>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain color="primary" dark v-bind="attrs" v-on="on">
                  Actions
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-btn
                    @click="
                      $router.push({
                        name: 'inbox.invoice',
                        params: { id: '123' },
                        query: { exception: true },
                      })
                    "
                    exact-path
                    rounded
                    depressed
                    dark
                    small
                    color="#2BD5AE"
                    class="text-lowercase px-2 my-4"
                    style="color: var(--v-primary-base)"
                  >
                    view
                  </v-btn>
                  <v-btn
                    @click="
                      $router.push({
                        name: 'inbox.invoice',
                        query: { review: false },
                      })
                    "
                    rounded
                    depressed
                    dark
                    small
                    color="primary"
                    class="text-lowercase px-2"
                    >review</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    type: {
      type: String,
    },
    amount: {
      type: Number,
    },
    exception: {
      type: String,
    },
    invoiceRef: {
      type: String,
    },
    requester: {
      type: String,
    },
    date: {
      type: String,
    },
    status: {
      type: String,
    },
    statusColor: {
      type: String,
    },
    chipColor: {
      type: String,
    },
    textColor: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
/* .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 8px !important;
} */

.chiptext {
  font-size: 0.6rem;
  font-weight: 500;
}
</style>
