<template>
  <div>
    <v-skeleton-loader type="table">
      <v-layout
        row
        wrap
        class="align-center pt-md-6 pb-md-4 px-8 text-body-2"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div>
            <p class="mb-0 primary--text font-weight-bold">#</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold text-center">Type</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center pl-4">
            <p class="mb-0 primary--text font-weight-bold text-center">
              Currency
            </p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-4 primary--text font-weight-bold">Amount</p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Requester</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div>
            <p class="mb-0 pl-md-1 primary--text font-weight-bold">Date</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-md-8 primary--text font-weight-bold">Actions</p>
          </div>
        </v-flex>
      </v-layout>
      <v-row v-if="allInvoices && allInvoices.length > 0">
        <v-col
          cols="12"
          v-for="invoice in displayedItems"
          :key="invoice.index"
          class="py-0 ma-md-0"
        >
          <DataTable
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="invoice.index"
            :id="invoice.id"
            :invoiceRef="invoice.invoicenumber || invoice.ref"
            :type="invoice.invoicetype"
            :requester="
              (invoice.vendor && invoice.vendor.vendorname) ||
              (invoice.customer &&
                invoice.customer.firstname + ' ' + invoice.customer.lastname) ||
              'N/A'
            "
            :date="invoice.created_at | date"
            :amount="invoice.total"
            :currency="invoice.currency"
            :status="invoice.status"
            :category="invoice.category"
            :textColor="
              invoice.invoicetype === 'customer' ? '#E3AA1C' : '#2BD5AE'
            "
          />

          <!-- Data table for mobile -->
          <DataTableCard
            v-if="$vuetify.breakpoint.smAndDown"
            :index="invoice.id"
            :invoiceRef="invoice.invoicenumber || 'N/A'"
            :type="invoice.invoicetype"
            :requester="
              (invoice.vendor && invoice.vendor.vendorname) ||
              (invoice.customer &&
                invoice.customer.firstname + ' ' + invoice.customer.lastname) ||
              'N/A'
            "
            :date="invoice.created_at | date"
            :amount="invoice.total || 'N/A'"
            :status="invoice.status || 'N/A'"
            :category="invoice.category || 'N/A'"
            :chipColor="
              invoice.invoicetype === 'customer' ? '#2BD5AE' : '#E3AA1C'
            "
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center pt-6" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
      <div
        class="pagination__container px-8"
        v-if="allInvoices && allInvoices.length > 0"
      >
        <BasePagination
          :current-page="currentPage"
          :visible-pages="visiblePageValue"
          :total-pages="totalPages"
          @page-changed="handlePageChange"
        />
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import DataTableCard from "./DataTableCard.vue";
import { mapGetters } from "vuex";
import pagination from "@/mixins/pagination";

import BasePagination from "@/components/BasePagination.vue";
export default {
  components: {
    DataTable,
    DataTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      searchQuery: "",
      itemsPerPage: 10,
    };
  },
  methods: {
    setSearchText(value) {
      this.searchQuery = value;
    },
  },
  computed: {
    ...mapGetters({
      allInvoices: "invoices/getInvoices",
      organisationId: "auth/organizationId",
    }),
    filteredInvoices() {
      if (this.searchQuery) {
        return this.allInvoices.filter((invoice) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => {
              if (invoice.invoicetype == "vendor") {
                return (
                  invoice.invoicenumber.toLowerCase().includes(v) ||
                  invoice.invoicetype.toLowerCase().includes(v) ||
                  invoice.total.toLowerCase().includes(v) ||
                  invoice.vendor?.vendorname.toLowerCase().includes(v)
                );
              }
            });
        });
      } else
        return this.allInvoices.filter((invoice) => {
          if (invoice.invoicetype === "vendor") {
            return invoice;
          }
        });
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredInvoices?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (
        this.filteredInvoices &&
        this.filteredInvoices.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredInvoices?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredInvoices?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
